@import '../../styles/variables';

.ctaWrap {
  border-radius: 16px;
  margin-top: 5px;
  padding: 30px 30px 40px 30px;
  position: relative;

  @media (min-width: $large-mobile) {
    padding: 30px;
  }
}
.ctaContent {
  position: relative;
}

.ctaBody {
  margin-bottom: 0;
  max-width: 600px;
}

.ctaTitle {
  margin-bottom: 10px;
}

.ctaButton {
  margin-top: 35px;
  padding: 0 28px;

  @media (min-width: $large-mobile) {
    margin-top: 25px;
    margin-bottom: 5px;
  }
}
